import {Column} from '@ozark/common/api/Column';
import {GroupAgentResponse} from '@ozark/functions/src/functions/express/private/types/Residual';
import startcase from 'lodash/startCase';
import {currentFormatter} from '../../ReportingPage';
import {formatYearMonth} from '../utils';
import {ResidualsRow} from './types';

export const GroupColumns: Column<ResidualsRow>[] = [
  {
    id: 'yearMonth',
    numeric: false,
    sortable: true,
    export: true,
    label: 'Date',
    selector: row => {
      return formatYearMonth(row.yearMonth);
    },
  },
  {
    id: 'mid',
    numeric: false,
    sortable: true,
    horizontallySticky: true,
    label: 'Merchant ID',
  },
  {
    id: 'agentName',
    numeric: false,
    sortable: true,
    horizontallySticky: true,
    width: 180,
    label: 'Agent Name',
  },
  {
    id: 'dba',
    numeric: false,
    sortable: true,
    horizontallySticky: true,
    width: 180,
    label: 'DBA',
  },
  {
    id: 'riskLevel',
    numeric: false,
    sortable: true,
    label: 'Risk Level',
    selector: row => startcase(row.riskLevel),
  },
  {
    id: 'feeModifierCount',
    numeric: true,
    sortable: false,
    label: 'Fee Modifiers',
  },
  {
    id: 'transactionCount',
    numeric: true,
    sortable: true,
    label: 'Transaction Count',
  },
  {
    id: 'bankcardSalesVolume',
    numeric: true,
    sortable: true,
    label: 'Sales Volume',
    selector: row => currentFormatter.format(row.bankcardSalesVolume),
  },
  {
    id: 'totalIncome',
    numeric: true,
    sortable: true,
    label: 'Merchant Income',
    selector: row => currentFormatter.format(row.totalIncome),
  },
  {
    id: 'groupExpense',
    numeric: true,
    sortable: true,
    label: 'Group Expense',
    selector: row => (row.groupExpense ? currentFormatter.format(row.groupExpense) : ''),
  },
  {
    id: 'groupNet',
    numeric: true,
    sortable: true,
    label: 'Gross Group Profit',
    selector: row => (row.groupNet ? currentFormatter.format(row.groupNet) : ''),
  },
  {
    id: 'groupSplitPercent',
    numeric: true,
    sortable: true,
    label: 'Group Split',
    selector: row => (row.groupSplitPercent ? `${row.groupSplitPercent}%` : ''),
  },
  {
    id: 'groupNetSplit',
    numeric: true,
    sortable: true,
    label: 'Group Net',
    selector: row => (row.groupNetSplit ? currentFormatter.format(row.groupNetSplit) : ''),
  },
  {
    id: 'agentExpense',
    numeric: true,
    sortable: true,
    label: 'Agent Expense',
    selector: row => (row.agentExpense ? currentFormatter.format(row.agentExpense) : ''),
  },
  {
    id: 'agentNet',
    numeric: true,
    sortable: true,
    label: 'Agent Net',
    selector: row => (row.agentNet ? currentFormatter.format(row.agentNet) : ''),
  },
  {
    id: 'agentSplitPercent',
    numeric: true,
    sortable: true,
    label: 'Agent Split',
    selector: row => (row.agentSplitPercent ? `${row.agentSplitPercent}%` : ''),
  },
  {
    id: 'agentNetSplit',
    numeric: true,
    sortable: true,
    label: 'Agent Payout',
    selector: row => (row.agentNetSplit ? currentFormatter.format(row.agentNetSplit) : ''),
  },
  {
    id: 'subAgentExpense',
    numeric: true,
    sortable: true,
    label: 'Sub Agent Expense',
    selector: row => (row.subAgentExpense ? currentFormatter.format(row.subAgentExpense) : ''),
  },
  {
    id: 'subAgentNet',
    numeric: true,
    sortable: true,
    label: 'Sub Agent Net',
    selector: row => (row.subAgentNet ? currentFormatter.format(row.subAgentNet) : ''),
  },
  {
    id: 'subAgentSplitPercent',
    numeric: true,
    sortable: true,
    label: 'Sub Agent Split',
    selector: row => (row.subAgentSplitPercent ? `${row.subAgentSplitPercent}%` : ''),
  },
  {
    id: 'subAgentNetSplit',
    numeric: true,
    sortable: true,
    label: 'Sub Agent Profit',
    selector: row => (row.subAgentNetSplit ? currentFormatter.format(row.subAgentNetSplit) : ''),
  },
  {
    id: 'groupProfit',
    numeric: true,
    sortable: true,
    label: 'Group Profit',
    selector: row => (row.groupProfit ? currentFormatter.format(row.groupProfit) : ''),
  },
  {
    id: 'agentProfit',
    numeric: true,
    sortable: true,
    label: 'Agent Profit',
    selector: row => (row.agentProfit ? currentFormatter.format(row.agentProfit) : ''),
  },
  {
    id: 'subAgentProfit',
    numeric: true,
    sortable: true,
    label: 'Sub Agent Profit',
    selector: row => (row.subAgentProfit ? currentFormatter.format(row.subAgentProfit) : ''),
  },
  {
    id: 'shareCount',
    numeric: true,
    sortable: false,
    label: 'Share Count',
  },
  {
    id: 'shareSplitPercent',
    numeric: true,
    sortable: false,
    label: 'Share Split',
    selector: row => (row.shareSplitPercent ? `${row.shareSplitPercent}%` : ''),
  },
  {
    id: 'shareNetSplit',
    numeric: true,
    sortable: false,
    label: 'Share Amount',
    selector: row => (row.shareNetSplit ? currentFormatter.format(row.shareNetSplit) : ''),
  },
  {
    id: 'groupProfitAdjusted',
    numeric: true,
    sortable: false,
    label: 'Adjusted Group Profit',
    selector: row =>
      row.groupProfitAdjusted ? currentFormatter.format(row.groupProfitAdjusted) : '',
  },
];

export const AgentAggregateGroupColumns: Column<GroupAgentResponse>[] = [
  {
    id: 'agentName',
    numeric: false,
    sortable: true,
    label: 'Agent Name',
    horizontallySticky: true,
    width: 150,
  },
  {
    id: 'masterAgentName',
    numeric: false,
    sortable: true,
    label: 'Master Agent Name',
    horizontallySticky: true,
    width: 180,
  },
  {
    id: 'feeModifierCount',
    numeric: true,
    sortable: false,
    label: 'Fee Modifiers',
  },
  {
    id: 'transactionCount',
    numeric: true,
    sortable: true,
    label: 'Transaction Count (Visa/MC/Disc)',
  },
  {
    id: 'transactionCountAmex',
    numeric: true,
    sortable: true,
    label: 'Transaction Count (Amex)',
  },
  {
    id: 'bankcardSalesVolume',
    numeric: true,
    sortable: true,
    label: 'Sales Volume (Visa/MC/Disc)',
    selector: row => currentFormatter.format(+row.bankcardSalesVolume),
  },
  {
    id: 'bankcardSalesVolumeAmex',
    numeric: true,
    sortable: true,
    label: 'Sales Volume (Amex)',
    selector: row => currentFormatter.format(+row.bankcardSalesVolumeAmex),
  },
  {
    id: 'averageTicket',
    numeric: true,
    sortable: true,
    label: 'Average Ticket',
    selector: row => {
      return row.averageTicket ? currentFormatter.format(+row.averageTicket) : 'N/A';
    },
  },
  {
    id: 'totalIncome',
    numeric: true,
    sortable: true,
    label: 'Merchant Income',
    selector: row => currentFormatter.format(+row.totalIncome),
  },
  {
    id: 'groupExpense',
    numeric: true,
    sortable: true,
    label: 'Group Expense',
    selector: row => (row.groupExpense ? currentFormatter.format(+row.groupExpense) : ''),
  },
  {
    id: 'groupNet',
    numeric: true,
    sortable: true,
    label: 'Gross Group Profit',
    selector: row => (row.groupNet ? currentFormatter.format(+row.groupNet) : ''),
  },
  {
    id: 'groupNetSplit',
    numeric: true,
    sortable: true,
    label: 'Group Net',
    selector: row => (row.groupNetSplit ? currentFormatter.format(+row.groupNetSplit) : ''),
  },
  {
    id: 'agentExpense',
    numeric: true,
    sortable: true,
    label: 'Agent Expense',
    selector: row => (row.agentExpense ? currentFormatter.format(+row.agentExpense) : ''),
  },
  {
    id: 'agentNet',
    numeric: true,
    sortable: true,
    label: 'Agent Net',
    selector: row => (row.agentNet ? currentFormatter.format(+row.agentNet) : ''),
  },
  {
    id: 'agentProfit',
    numeric: true,
    sortable: true,
    label: 'Agent Profit',
    selector: row => (row.agentProfit ? currentFormatter.format(+row.agentProfit) : ''),
  },
  {
    id: 'agentAdjustments',
    numeric: true,
    sortable: false,
    label: 'Agent Adjustments',
    selector: row => currentFormatter.format(+(row.agentAdjustments ?? '0')),
  },
  {
    id: 'agentPayout',
    numeric: true,
    sortable: false,
    label: 'Agent Payout',
    selector: row =>
      row.agentProfit
        ? currentFormatter.format(+(row.agentProfit ?? '0') + +(row.agentAdjustments ?? '0'))
        : '',
  },
  {
    id: 'subAgentExpense',
    numeric: true,
    sortable: true,
    label: 'Sub Agent Expense',
    selector: row => (row.subAgentExpense ? currentFormatter.format(+row.subAgentExpense) : ''),
  },
  {
    id: 'subAgentNet',
    numeric: true,
    sortable: true,
    label: 'Sub Agent Net',
    selector: row => (row.subAgentNet ? currentFormatter.format(+row.subAgentNet) : ''),
  },
  {
    id: 'subAgentProfit',
    numeric: true,
    sortable: true,
    label: 'Sub Agent Profit',
    selector: row => (row.subAgentProfit ? currentFormatter.format(+row.subAgentProfit) : ''),
  },
  {
    id: 'subAgentAdjustments',
    numeric: true,
    sortable: false,
    label: 'Sub Agent Adjustments',
    selector: row => currentFormatter.format(+(row.subAgentAdjustments ?? '0')),
  },
  {
    id: 'subAgentPayout',
    numeric: true,
    sortable: false,
    label: 'Sub Agent Payout',
    selector: row =>
      row.subAgentProfit
        ? currentFormatter.format(
            +(row.subAgentNetSplit ?? '0') + +(row.subAgentAdjustments ?? '0')
          )
        : '',
  },
  {
    id: 'groupProfit',
    numeric: true,
    sortable: true,
    label: 'Group Profit',
    selector: row => (row.groupProfit ? currentFormatter.format(+row.groupProfit) : ''),
  },
  {
    id: 'shareCount',
    numeric: true,
    sortable: false,
    label: 'Share Count',
  },
  {
    id: 'shareNetSplit',
    numeric: true,
    sortable: false,
    label: 'Share Amount',
    selector: row => (row.shareNetSplit ? currentFormatter.format(+row.shareNetSplit) : ''),
  },
  {
    id: 'groupProfitAdjusted',
    numeric: true,
    sortable: false,
    label: 'Adjusted Group Profit',
    selector: row =>
      row.groupProfitAdjusted ? currentFormatter.format(+row.groupProfitAdjusted) : '',
  },
];
