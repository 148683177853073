import {Grid} from '@mui/material';
import {Square} from '@ozark/common/components';
import {PaginatedGroupTilesResponse} from '@ozark/functions/src/functions/express/private/types/Residual';
import {currentFormatter} from '../../ReportingPage';

interface Props {
  residualTiles: PaginatedGroupTilesResponse | null;
}
export const GroupTiles = ({residualTiles}: Props) => {
  return (
    <>
      <Grid item xs={3}>
        <Square
          lines={{
            'Visa/MC/Disc Transactions': parseFloat(residualTiles?.transactionCount || '0'),
          }}
          center
        />
      </Grid>

      <Grid item xs={3}>
        <Square
          lines={{
            'Amex Transactions': parseFloat(residualTiles?.transactionCountAmex || '0'),
          }}
          center
        />
      </Grid>

      <Grid item xs={3}>
        <Square
          lines={{
            'Visa/MC/Disc Sales Volume': currentFormatter.format(
              parseFloat(residualTiles?.bankcardSalesVolume || '0')
            ),
          }}
          center
        />
      </Grid>

      <Grid item xs={3}>
        <Square
          lines={{
            'Amex Sales Volume': currentFormatter.format(
              parseFloat(residualTiles?.bankcardSalesVolumeAmex || '0')
            ),
          }}
          center
        />
      </Grid>

      <Grid item xs={3}>
        <Square
          lines={{
            'Total Agent Payout': currentFormatter.format(
              parseFloat(residualTiles?.agentNetSplit || '0')
            ),
          }}
          center
        />
      </Grid>

      <Grid item xs={3}>
        <Square
          lines={{
            'Total Group Profit': currentFormatter.format(
              residualTiles?.groupProfitAdjusted ||
                Number.parseFloat(residualTiles?.groupProfit || '0')
            ),
          }}
          center
        />
      </Grid>
    </>
  );
};
